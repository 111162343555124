<template>
  <div class="order-online">
    <div class="order-header" :style="{'background':'url(' + $http + '/img/videoTopBack.png)'}">
      <span>在线订单</span>
      <img :src="$http + '/zh/orderOnline/closeIcon.png'" @click="closeOrderOnline" alt="" />
    </div>
    <div class="order-body">
      <!-- 订单信息 -->
      <img
        class="order-title-img"
        :src="$http + '/zh/orderOnline/orderInfo.png'"
        alt="订单信息"
      />
      <div class="order-table">
        <el-table
          :data="tableData"
          style="width: 100%"
          :header-cell-style="{
            'text-align': 'center',
            'border-bottom-color': '#eac596',
            'font-size': '1.25rem',
            'color': '#000',
            'font-family': 'PingFang SC',
          }"
          :row-style="{
            height: '5.625rem',
            'line-height': '5.625rem'
          }"
          :cell-style='changeCellStyle'
        >
          <el-table-column prop="productName" label="产品"></el-table-column>
          <el-table-column prop="originalPrice" label="单价" align="center">
            <template slot-scope="scope"> ¥ {{ scope.row.originalPrice }} </template>
          </el-table-column>
          <el-table-column label="数量" align="center">
            <template slot-scope="scope">
              <div class="custom-outside">
                <div class="custom-count">
                  <img
                    v-if="scope.row.count == 1"
                    :src="$http + '/zh/orderOnline/minusDisable.png'"
                    alt=""
                  />
                  <img
                    v-if="scope.row.count >= 2"
                    :src="$http + '/zh/orderOnline/minusEnable.png'"
                    @click="resetCountFunc('minus', scope.$index)"
                    alt=""
                  />
                  <input
                    @input="countSet(scope.$index)"
                    v-model="scope.row.count"
                  />
                  <img
                    v-if="scope.row.count == max"
                    :src="$http + '/zh/orderOnline/addDisable.png'"
                    alt=""
                  />
                  <img
                    v-if="scope.row.count < max"
                    :src="$http + '/zh/orderOnline/addEnable.png'"
                    @click="resetCountFunc('add', scope.$index)"
                    alt=""
                  />
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="原价" align="center">
            <template slot-scope="scope">
              <div style="position: relative;">
                <span style="position: absolute;display: inline-block;width: 92px;height: 1px;background:rgba(0,0,0,.8);left:50%;top:50%;transform: translateX(-50%);"></span>
                <span>¥ {{ scope.row.defaultPrice }}</span>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            prop="preferentialMode"
            label="优惠方式"
            align="center"
          ></el-table-column>
          <el-table-column label="总计" align="center">
            <template slot-scope="scope">
              <span
                style="
                  color: #ff0a0a;
                  font-weight: 500;
                  font-family: PingFang SC;
                  font-size: 1.875rem;
                "
                >¥ {{ scope.row.originalPrice * scope.row.count }}</span
              >
            </template>
          </el-table-column>
        </el-table>
      </div>
      <!-- 联系方式 -->
      <img
        class="order-title-img"
        :src="$http + '/zh/orderOnline/connectWay.png'"
        alt="联系方式"
      />
      <!-- 联系方式表单 -->
      <div class="order-connect">
        <!-- 邮箱 -->
        <div class="connect-form">
          <div class="form-label">
            <span>*</span>
            邮箱：
          </div>
          <div class="form-value">
            <el-input
              v-model="connectForm.email"
              placeholder="请输入正确的邮箱地址，用于验证邮箱，必填"
              class="email-input"
              @input="verifyEmail"
            ></el-input>
          </div>
          <div v-if="!sendEmail" class="sure-email" @click="submitOrder">确认邮箱</div>
          <div v-else class="finisn-email">
            <img src="../../assets/img/rightIcon.png" alt="">
            <span>已发送至邮箱</span>
          </div>
        </div>
        <!-- 邮箱娇艳 -->
        <div class="connect-form">
          <div class="form-label"></div>
          <div
            class="form-value form-empty-tips form-tips"
            v-if="emailStatus === 'empty'"
          >
            <img :src="$http + '/zh/orderOnline/tipsIcon.png'" alt="" />
            <div>
              温馨提示：邮箱会成为产品下载及查收产品序列号License的主要联系方式，请确认邮箱，再进行下一步操作。
            </div>
          </div>
          <div
            class="form-value form-success-tips form-tips"
            v-if="emailStatus === 'success'"
          >
            <img :src="$http + '/zh/orderOnline/tipsSuccessIcon.png'" alt="" />
            <div>
              您的邮箱地址填写正确，请填写姓名后，点击“确认邮箱”，您的邮箱将收到一封确认邮件，点击确认后可继续购买。
            </div>
          </div>
          <div
            class="form-value form-error-tips form-tips"
            v-if="emailStatus === 'error'"
          >
            <img :src="$http + '/zh/orderOnline/tipsErrorIcon.png'" alt="" />
            <div>请输入正确的邮箱地址</div>
          </div>
        </div>
        <!-- 用户名 -->
        <div class="connect-form">
          <div class="form-label">
            <span>*</span>
            姓名：
          </div>
          <div class="form-value">
            <el-input
              v-model="connectForm.realName"
              placeholder="请输入姓名，必填"
              class="same-input"
            ></el-input>
          </div>
        </div>
        <!-- 联系电话 -->
        <div class="connect-form">
          <div class="form-label">电话：</div>
          <div class="form-value">
            <el-input
              v-model="connectForm.mobile"
              placeholder="请输入电话号码"
              class="same-input"
              @input="verifyMobile"
            ></el-input>
          </div>
        </div>
        <!-- 电话校验 -->
        <div class="connect-form">
          <div class="form-label"></div>
          <div
            class="form-value form-error-tips form-tips"
            style="width: 368px"
            v-if="mobileStatus === 'error'"
          >
            <img :src="$http + '/zh/orderOnline/tipsErrorIcon.png'" alt="" />
            <div>请输入正确的手机号码</div>
          </div>
        </div>
      </div>
      <!-- 支付结果 -->
      <div class="pay-result" v-if="payStatus == 'SUCCESS'">
        <img
            class="order-title-img"
            :src="$http + '/zh/orderOnline/payWay.png'"
            alt="支付方式"
        />
        <div class="result-info">
            <div class="result-img">
                <img :src="$http + '/zh/orderOnline/paySuccessResult.png'" alt="">
                <span>支付成功!</span>
            </div>
            <div class="result-text">
                <div>尊敬的 {{connectForm.realName}} 用户:</div>
                <div>您好!</div>
                <div>您已支付成功，请到邮件查收产品下载链接和序列号License。</div>
            </div>
        </div>
      </div>
      <div class="pay-result" v-if="payStatus == 'FAIL'">
        <img
            class="order-title-img"
            :src="$http + '/zh/orderOnline/payWay.png'"
            alt="支付方式"
        />
        <div class="result-info result-fail">
            <div class="result-img">
                <img :src="$http + '/zh/orderOnline/payFailResult.png'" alt="">
                <span>支付失败!</span>
            </div>
            <div class="result-text">
                <div>尊敬的 {{connectForm.realName}} 用户:</div>
                <div>您好!</div>
                <div>您的支付遇到问题，请重新支付</div>
            </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { addOrder, productInfoGet, payResult, productInfoQuery, productInfoItems } from '@/api/order'
export default {
  data() {
    return {
      max: 10, // 购买最大值
      // 可购买的产品
      tableData: [],
      //   联系方式表单
      connectForm: {
        email: "", // 邮箱
        realName: "", // 用户名
        mobile: "", // 联系电话
      },
      emailStatus: "empty", // 判断邮箱是否正确 empty: 未输入 success: 正确 error: 错误
      mobileStatus: "empty", // 判断手机号是否正确 empty/success都不展示。仅为error展示
      isFirst: true, // 首次查询支付结果接口触发获取产品信息的接口
      timer: null, // 循环查询支付结果的定时器
      payStatus: 'INITIAL',
      keepTime: 0, // 循环查询时间--60秒结束
      fromWay: true, // 展示原因。是否
      sendEmail: false, // 是否发送过邮箱了
      throttleTime: 0, // 节流时间
      throttleTimer: '', // 节流定时器 
    };
  },
  mounted(){
    this.getProductInfo()
    this.keepTime = 60
  },
  methods: {
    // el-table自定义样式
    changeCellStyle(row){
      if(row.column.label === '产品'){
        return "paddingLeft:2.0625rem;height:5.625rem !important"
      }else{
        return "height:5.625rem !important"
      }
    },
    // 获取商品信息
    getProductInfo(){
      productInfoGet({id: '1'}).then(res=>{
        res.data.list.forEach(e=>{
          e.count = 1
          e.preferentialMode = '冬季优惠'
          e.defaultPrice = '1388'
        })
        this.tableData = res.data.list
        // 如果是支付（不管成功还是失败）之后跳转回来查询支付结果
        if(this.$route.query.tradeNo){
          this.payResultFunc()
          // this.timer = setInterval(() => {
          //   this.payResultFunc()
          //   this.keepTime--
          // }, 1000);
        }
      })
    },
    // 获取查询结果
    payResultFunc(){
      // 查询支付结果
      payResult({
        tradeNo: this.$route.query.tradeNo
      }).then(res=>{
        console.log(res.data, '查询结果');
        // 仅在此一次查询执行
        if(this.isFirst){
          this.getProductsItem(res.data.orderNo)
          this.getProductsOrder(res.data.orderNo)
        }
        this.isFirst = false // 是否为第一次查询
        this.payStatus = res.data.payStatus // 查询状态
        // 查询60秒，超时停止查询
        if(this.keepTime<=0){
          return
        }
        // 如果没查询到结果，继续查询
        if(res.data.payStatus=="INITIAL"){
          this.keepTime--
          this.payResultFunc()
        }
      })
    },
    // 获取产品产品填写的数量
    getProductsItem(id){
      productInfoItems({
        id
      }).then(res=>{
        res.data.list.forEach(_=>{
          let Index = this.tableData.findIndex(e=>e.id == _.productId) // 判断产品的产品id是否包含在列表之内
          // 如果产品与列表的id一致说明该产品已经被购买了，将产品数量添加至列表内
          if(Index!==-1){
            this.tableData[Index].count = _.amount
          }
        })
      })
    },
    // 获取订单信息， 获取用户名、邮箱、手机号信息
    getProductsOrder(id){
      productInfoQuery({
        id,
      }).then(_=>{
        let orderInfo = _.data.list[0] ? _.data.list[0] : {}
        if(orderInfo.email){
          this.connectForm.email = orderInfo.email
          this.connectForm.realName = orderInfo.realName
          this.connectForm.mobile = orderInfo.mobile
        }
      })
    },
    // 节流方法
    throttleFunc(){
      if(this.throttleTime == 0){
        this.throttleTime = 2
        this.throttleTimer = setInterval(() => {
          this.throttleTime--
          if(this.throttleTime == 0){
            clearInterval(this.throttleTimer)
          }
        }, 1000);
        return true
      }else{
        return false
      }
    },
    // 添加订单
    submitOrder(){
      // 节流
      if(!this.throttleFunc()){
        return
      }
      if(this.emailStatus === 'empty'){
        this.$message.warning('请输入邮箱，再进行下一步操作。')
        return
      }
      if(this.emailStatus === 'error'){
        this.$message.warning('当前输入邮箱格式错误，请仔细检查邮箱，再进行下一步操作。')
        return
      }
      if(!this.connectForm.realName){
        this.$message.warning('请输入姓名，在进行下一步操作。')
        return
      }
      let productItemList = this.tableData.map(_=>{
        return {
          productId: _.id,
          amount: _.count
        }
      })
      if(!productItemList.every(_=>_.amount>0)){
        this.$message.warning('请至少选择一个产品后，在提交订单。')
        return
      }
      addOrder({
        realName: this.connectForm.realName,
        email: this.connectForm.email,
        mobile: this.connectForm.mobile,
        callbackUrl: window.location.href.indexOf('?') === -1 ? window.location.href : window.location.href.split('?')[0],
        productItemList,
        sourceType: 1
      }).then(res=>{
        this.$message.success('提交成功')
        this.sendEmail = true
      })
    },
    // 购买最大值限制
    countSet(index){
        this.tableData[index].count = this.tableData[index].count.replace(/[^\d]/g, '')
        this.tableData[index].count = this.tableData[index].count > 10 ? 10 : this.tableData[index].count 
        this.tableData[index].count = this.tableData[index].count == 0 ? 1 : this.tableData[index].count 
    },
    // 增加/减少产品数量
    resetCountFunc(type, index) {
      if (type === "add") {
        this.tableData[index].count++;
      }
      if (type === "minus") {
        this.tableData[index].count--;
      }
    },
    // 判断邮箱
    verifyEmail() {
      if (this.connectForm.email) {
        let reg = /^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/;
        this.emailStatus = reg.test(this.connectForm.email)
          ? "success"
          : "error";
      } else {
        this.emailStatus = "empty";
      }
    },
    // 检查手机号
    verifyMobile() {
      if (this.connectForm.mobile) {
        let reg = /^(13[0-9]|14[5|7]|15[0|1|2|3|4|5|6|7|8|9]|18[0|1|2|3|5|6|7|8|9])\d{8}$/;
        this.mobileStatus = reg.test(this.connectForm.mobile)
          ? "success"
          : "error";
      } else {
        this.mobileStatus = "empty";
      }
    },
    // 关闭在线订单弹窗
    closeOrderOnline(){
      this.$emit('closeOnline')
      // clearInterval(this.timer)
    }
  },
};
</script>

<style scoped lang="scss">
.order-online {
  position: fixed;
  left: 180px;
  top: 6%;
  z-index: 2000;
  width: 1560px;
  height: 88%;
  border: 1px solid #a7a7a7;
  border-radius: 12px;
  .order-header {
    padding: 0 19px 0 31px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 55px;
    color: #fff;
    background-repeat: no-repeat !important;
    background-size: 100% 100% !important;
    // background: rgba(154, 154, 154, 0.6);
    // backdrop-filter: blur(10px);
    // -webkit-backdrop-filter: blur(10px);
    // backdrop-filter: blur(
    //   10px
    // ); /* This is the fallback for Safari that doesn't support backdrop-filter */
    img {
      width: 22px;
      height: 22px;
      cursor: pointer;
    }
    span {
      font-size: 24px;
      font-family: PingFang SC;
      font-weight: 600;
    }
  }
  .order-body {
    padding: 2px 72px 0 72px;
    box-sizing: border-box;
    height: calc(100% - 55px);
    overflow-y: auto;
    background: #fff;
    border-radius: 0 0 12px 12px;
    // 订单信息
    .order-title-img {
      width: 224px;
      height: 84px;
    }
    .order-table {
      margin-left: 2%;
      width: 96%;
      // min-height: 335px;
      margin-bottom: 20px;
      .el-table {
        font-size: 22px;
        color: #000;
        font-family: PingFang SC;
        ::v-deep tr{
          background: #fafafa;
        }
        ::v-deep th{
          background: #fafafa;
        }
        ::v-deep .el-table__row td {
          border-bottom-color: #fff !important;
        }
        ::v-deep .cell{
          height: 90px;
          line-height: 90px;
        }
        .custom-outside {
          margin-top: 32px;
          display: flex;
          align-items: center;
          justify-content: center;
          .custom-count {
            display: flex;
            align-items: center;
            justify-content: space-between;
            background: #ebebeb;
            width: 55%;
            border-radius: 24px;
            img {
              width: 24px;
              height: 24px;
              cursor: pointer;
            }
            input {
              width: 60px;
              border: none;
              outline: 0;
              height: 26px;
              text-align: center;
              background: transparent;
              font-size: 22px;
            }
          }
        }
      }
    }
    // 联系方式
    .order-connect {
      padding-left: 42px;
    //   padding-bottom: 48px;
      box-sizing: border-box;
      .connect-form {
        display: flex;
        align-items: center;
        margin: 12px 0;
        .form-label {
          width: 90px;
          font-size: 22px;
          color: #000;
          font-family: PingFang SC;
          text-align: right;
          span {
            color: red;
          }
        }
        .form-value{
          line-height: 50px;
          height: 50px;
          .email-input{
            width: 487px; height: 50px
          }
          .same-input{
            width: 368px; height: 50px
          }
        }
        .form-tips {
          display: flex;
          align-items: flex-start;
          width: 702px;
          height: 96px;
          border-radius: 4px;
          border: 1px solid rgba(255, 255, 255, 0.8);
          padding: 12px 27px;
          box-sizing: border-box;
          img {
            margin: 8px 22px 0 0;
            width: 18px;
            height: 18px;
          }
          div {
            width: 713px;
            height: 68px;
            font-size: 20px;
            font-family: PingFangSC, PingFang SC;
            font-weight: 400;
            color: #666666;
            line-height: 34px;
          }
        }
        // 未输入邮箱
        .form-empty-tips {
          background: #e7f3fc;
        }
        // 正确邮箱
        .form-success-tips {
          background: #f3fbf1;
        }
        // 错误邮箱
        .form-error-tips {
          background: #ffeee7;
          height: 62px;
          div {
            width: 686px;
            height: 62px;
            font-size: 20px;
            font-family: PingFangSC, PingFang SC;
            font-weight: 400;
            color: #666666;
            line-height: 34px;
          }
        }
        .sure-email {
          width: 186px;
          height: 40px;
          background: url("../../assets/zh/orderOnline/button.png") no-repeat;
          background-size: 100% 100%;
          font-size: 22px;
          color: #000;
          text-align: center;
          line-height: 43px;
          margin-left: 30px;
          cursor: pointer;
        }
        .finisn-email{
          width: 186px;
          height: 40px;
          background: #f3fbf1;
          color: #87d370;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-left: 30px;
          img{
            width: 18px;
            height: 18px;
            margin-right: 6px;
          }
        }
      }
    }
    // 支付方式
    .pay-result{
      .result-info{
        margin-top: 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
        background: #FAFAFA;
        height: 324px;
        margin-bottom: 76px;
        .result-img{
            margin-top: 40px;
            display: flex;
            align-items: center;
            img{
                width: 90px;
                height: 90px;
                margin-right: 20px;
            }
            span{
                font-size: 34px;
                font-family: PingFangSC, PingFang SC;
                font-weight: 500;
                color: #000000;
                line-height: 48px;
            }
        }
        .result-text{
            margin-top: 28px;
            width: 662px;
            height: 126px;
            div{
                text-align: center;
                font-size: 24px;
                font-family: PingFangSC, PingFang SC;
                color: #666666;
                line-height: 42px;
            }
        }
      }
      .result-fail{
        background: #fff6f6;
      }
    }
  }
}
@media screen and (max-width: 450px) {
  .order-online {
    top: 120px;
    height: 845px;
    .order-body{
      height: 790px;
    }
  }
}
</style>